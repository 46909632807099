import React, { Fragment, useContext } from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql }  from 'gatsby'
import ds from '../theme'
import IcoStorage from '@material-ui/icons/Storage'
import IcoBrightness from '@material-ui/icons/Brightness4'
import IcoAcUnit from '@material-ui/icons/AcUnit'
import IcoEq from '@material-ui/icons/GraphicEq'
import IcoVpn from '@material-ui/icons/VpnLock'
import IcoNetwork from '@material-ui/icons/NetworkCheck'
import Check from '@material-ui/icons/CheckCircle'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Body2 from './../components/Body2'
import Div from './../components/Div'
import Body1 from './../components/Body1'
import { css } from '@emotion/core'

import H3 from '../components/H3'
import H5 from '../components/H5'
import Paragraph from '../components/Paragraph'

import ImgAgendeSuaTour from '../images/agende-sua-tour.svg'
import DcNordeste from '../images/data-centers/dc-nordeste-1.jpg'
import DcNordeste2 from '../images/data-centers/dc-nordeste-2.jpg'
import DcNordeste3 from '../images/data-centers/dc-nordeste-3.jpg'
import DcNordeste4 from '../images/data-centers/dc-nordeste-4.jpg'
import DcNordeste5 from '../images/data-centers/dc-nordeste-5.jpg'
import DcNordeste7 from '../images/data-centers/dc-nordeste-7.jpg'

import { withSolutions, SolutionContext } from '../components/SolutionProvider';

import { Container, mq } from '../components/styles/Layout.styles'
import { 
  styledDataCenters,
  styleEstructure,
  header,
  scheduleTour,
  imgSmall,
  imgMedium
} from '../components/styles/DataCenters.styles'
import AgendeSuaTour from '../components/ContactForm'

const fields = [
  {
    label: 'Nome Completo',
    name: 'your-name',
    type: 'text',
    required: true
  },
  {
    label: 'Email',
    name: 'your-email',
    type: 'email',
    required: true
  },
  {
    label: 'Telefone',
    name: 'your-phone',
    type: 'text',
    required: true,
    mask: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  },
  {
    label: 'Mensagem',
    name: 'your-message',
    type: 'text',
    multiline: true,
    rows: 6,
    required: true
  }
]

const DcItem = ({
  img,
  title,
  children,
  icon: Icon,
  src,
  id
}) => {
  const [active, setIsActive] = useContext(SolutionContext)
  const isActive = active === id
  return (
    <div
      onClick={() => setIsActive(id)}
      style={{
        padding: 24,
        backgroundColor: '#FFFFFF',
        borderRadius: 16,
      }}
      css={
        css(
          mq({
            '&:not(:last-child)': {
              marginBottom: ds.space(3)
            } 
          })
        )
      }
    >
      <Div
        flexDirection='row'
        alignItems='center'
      >
        <div
          style={{
            width: 68,
            height: 68,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            borderRadius: '50%',
            backgroundColor: '#f3f5f5',
            marginRight: ds.space(3)
          }}
        >
          {Icon && <Icon
            style={{
              color: 'rgba(0, 0, 0, 0.38)'
            }}
          />}
          {src && <img
            src={src}
          />}
        </div>
        <Body1
          selected
          on='onSurface'
          variant='highEmphasis'
          style={{
            width: 170,
            lineHeight: 1.46,
            letterSpacing: 0.5
          }}
        >
          {title}
        </Body1>
      </Div>
      {children && (
        <Div
          style={{
            position: 'relative',
            opacity: isActive ? 1 : 0,
            maxHeight: isActive ? 700 : 0,
            transition: isActive ? 'max-height 2s, opacity 0.35s 0.35s' : 'max-height 0.8s 0.35s, z-index 0.7s 0.7s, opacity 0.7s',
            zIndex: isActive ? 1 : -1
          }}
        >
          <Body2
            color='black'
            variant='mediumEmphasis'
            mt={[3]}
          >
            {children}
          </Body2>
        </Div>
      )}
    </div>
  )
}

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  return (
    <Fragment>
      <section css={styledDataCenters}>
        <Container>
          <Row
            flexDirection={['column', 'row']}
            css={header}
          >
            <Column
              width={[1]}
            >
              <H3>
                <span>ESTRUTURA</span>
                Data Center Nordeste
              </H3>
              <Paragraph>Com a missão de oferecer o melhor serviço para todas as regiões do país, construímos o mais moderno e seguro data center do Norte e Nordeste. Além de uma infraestrutura certificada, com alta disponibilidade, segurança e desempenho de redes, dispomos de profissionais capacitados e experientes para desenvolver projetos com soluções  customizadas, de acordo com a sua necessidade.</Paragraph>
            </Column>
          </Row>
          <Row
            mt={[40]}
          >
            <Div
              style={{
                width: '100%',
                maxWidth: 1000,
                margin: '0 auto'
              }}
            >
              <img style={{ width: '100%', height: 'auto' }} src={DcNordeste7} />
            </Div>
          </Row>
          <Row
            mt={[4, 4, 8, 8]}
          >
            <Div
              style={{
                width: '100%',
                maxWidth: 1000,
                margin: '0 auto',
                display: 'inline-block'
              }}
            >
              <img css={imgSmall} src={DcNordeste2} />
              <img css={imgSmall} src={DcNordeste3} />
              <img css={imgSmall} src={DcNordeste4} />
            </Div>
          </Row>
          <Row
            mt={[0, 0, 6, 6]}
          >
            <Div
              style={{
                width: '100%',
                maxWidth: 1000,
                margin: '0 auto',
                display: 'inline-block'
              }}
            >
              <img css={imgMedium} src={DcNordeste5} />
              <img css={imgMedium} src={DcNordeste} />
            </Div>
          </Row>
        </Container>
      </section>
      <section css={styleEstructure}>
        <Container>
          <Row
            justifyContent={'center'}
            pt={40}
            pb={30}
          >
            <Column>
              <H5>Saiba mais sobre<br /> nossa estrutura</H5>
            </Column>
          </Row>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'row']}
            pb={50}
          >
            <Column
              mb={[8, 8,  8,  0]}
            >
              <Div
                maxWidth={['100%', '100%', 422]}
              >
                <DcItem
                  icon={IcoStorage}
                  title='Espaço e Infraestrutura'
                  id='solution01'
                >
                  <Div
                    width={['100%', '100%', '360px']}
                  >
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      1.500 metros quadrados
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Piso elevado com 80cm de altura
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Disponibilidade de implementação de racks, cage “gaiolas” e espaço de antena no telhado
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Soluções Turn-Key
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Lounge do espaço de trabalho do cliente Colocation
                    </Div>
                    <Div
                      flexDirection={['row']}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Estacionamento no local
                    </Div>
                  </Div>
                </DcItem>
                <DcItem
                  icon={IcoBrightness}
                  title='Poder de Alta Disponibilidade'
                  id='solution02'
                >
                  <Div
                    width={['100%', '100%', '360px']}
                  >
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Capacidade total de instalação de 1.500 KVA com alimentação primária de 13.700 Volts
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Simultaneamente sustentável e tolerante a falhas
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Sistema de distribuição dual A&B do ponto principal de serviço ao ponto de conexão do rack com o servidor
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Seis no-breaks de 275 KVA cada que atendem as distribuições A&B, respectivamente, à carga crítica conectada
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Sistema gerador paralelo de alta disponibilidade (N + 1) de 4 x 680 KW com 30.000 litros de combustível diesel de reserva total
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Alimentação de energia redundante e escalável de até 10 KW por rack
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      PUE Power Usage Effectiveness) média de menos de 1.5
                    </Div>
                  </Div>
                </DcItem>
                <DcItem
                  icon={IcoAcUnit}
                  title='Refrigeração de Alta Eficiência'
                  id='solution03'
                >
                  <Div
                    width={['100%', '100%', '360px']}
                  >
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Total de 4 x 100 toneladas de resfriadores a ar independentes com água inteligente economizando através de pré-resfriamento, quando a temperatura ambiente ultrapassa 30° C
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Total de 300 toneladas de capacidade de refrigeração redundante (N + 1)
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Ventiladores EC de velocidade variável de última geração, com eficiência energética em unidades externas de rejeição de calor e unidades internas de tratamento de precisão
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Fluxo de ar usando um piso elevado de 80cm
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Contenção do corredor frio
                    </Div>
                    <Div
                      flexDirection={['row']}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Suporte escalonável para refrigeração de 1 KW até 10 KW por rack
                    </Div>
                  </Div>
                </DcItem>
              </Div>
            </Column>
            <Column
              mt={[8, 8, 0, 0]}
            >
              <Div
                maxWidth={['100%', '100%', 422]}
              >
                <DcItem
                  icon={IcoEq}
                  title='Monitoramento Efetivo'
                  id='solution04'
                >
                  <Div
                    width={['100%', '100%', '360px']}
                  >
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Controles e monitoramento de missão crítica de última geração
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                        Patente HD-World DCIM que faz monitoramentos em tempo real e gera relatórios de entrega de energia e sistemas de refrigeração para otimizar o tempo de atividade e a operação
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Gerenciamento e planejamento eficazes da capacidade
                    </Div>
                    <Div
                      flexDirection={['row']}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Portal do cliente Core com dados instantâneos de utilização de energia 24x7, gerenciamento de chamados, relatórios de utilização de largura de banda e gerenciamento de crachás de acesso a contas de clientes
                    </Div>
                  </Div>
                </DcItem>
                <DcItem
                  icon={IcoVpn}
                  title='Segurança Impenetrável'
                  id='solution05'
                >
                  <Div
                    width={['100%', '100%', '360px']}
                  >
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Extensivos sistemas de vigilância de vídeo 24x7x365 com detecção automática de movimento de alta definição e armazenamento de gravação digital por mais de 90 dias
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Controle de acesso de autenticação dupla para zonas críticas com leitor de cartões e reconhecimento facial e biométrico
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Segurança de perímetro de fio de navalha
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Armadilhas de homem anti-tailgating
                    </Div>
                    <Div
                      flexDirection={['row']}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Diretores de segurança dedicados 24x7 no local e equipe de monitoramento
                    </Div>
                  </Div>
                </DcItem>
                <DcItem
                  icon={IcoNetwork}
                  title='Conectividade e Largura de banda'
                  id='solution06'
                >
                  <Div
                    width={['100%', '100%', '360px']}
                  >
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Conexão aos principais pontos de troca de tráfego (IX)
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Conexão aos principais pontos de troca de tráfego (IX)
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Carrier Neutral
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Pontos de entrada de fibra óptica subterrânea redundantes
                    </Div>
                    <Div
                      flexDirection={['row']}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Soluções personalizadas de largura de banda
                    </Div>
                  </Div>
                </DcItem>
              </Div>
            </Column>
          </Row>
        </Container>
      </section>
      <section css={styledDataCenters}>
        <Container>
          <Row
            justifyContent={['center']}
            css={scheduleTour}
            flexDirection={['column', 'column', 'row', 'row']}
          >
            <Column
              mb={[30, 30, 0, 0]}
            >
              <Div
                maxWidth={['100%', '100%', 328]}
              >
                <Div
                  maxWidth={['100%']}
                  style={{
                    marginBottom: 24
                  }}
                >
                  <img
                    src={ImgAgendeSuaTour} 
                    style={{
                      width: 130
                    }}
                  />
                </Div>
                <H5>Agende seu tour</H5>
                <Paragraph>Quer vir conhecer de perto uma das melhores infraestruturas de data center do mundo? Agende sua visita preenchendo o formulário e aguarde que em breve retornaremos sua solicitação com as instruções.</Paragraph>
              </Div>
            </Column>
            <Column>
              <Div
                maxWidth={['100%', '100%', 328]}
              >
                <AgendeSuaTour
                  teste='aqui'
                  fields={fields}
                  formId={30}
                  submitText='Enviar solicitação'
                />
              </Div>
            </Column>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo title='Data Center Nordeste' />
      <PageTemplate
        title='Data Center Nordeste'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "ilustra-o-home-1@3x.png" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default withSolutions(StaticPage)
